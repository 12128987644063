<script>
    import { useToast } from 'vue-toastification'
    import axios        from 'axios'
    import User         from '../data/User'
    import Lottery      from '../data/Lottery'
    import Permission   from '../data/Permission'
    import Modal        from '../components/Modal.vue'
    import InputField   from '../components/InputField.vue'

    export default {
        components: {
            Modal,
            InputField,
        },
        data() {
            return {
                users           : null  ,
                permissions     : null  ,
                lotteries       : null  ,
                editing         : null  ,
                editLoading     : false ,
                modifiedUser    : null  ,
            }
        },
        computed: {
            apiUrl() {
                return process.env.VUE_APP_API_URL
            },
            haveData() {
                return this.users && this.permissions && this.lotteries
            },
            authedUser() {
                return this.$store.state.authedUser
            },
        },
        methods: {
            async getData() {
                this.getUsers()
                this.getPermissions()
                this.getLotteries()
            },
            async getUsers() {
                axios.defaults.withCredentials = true

                const url      = this.apiUrl + '/users'
                const response = await axios.get(url, { withCredentials: true })

                this.users = response.data.users.map(User)
            },
            async getPermissions() {
                axios.defaults.withCredentials = true

                const url      = this.apiUrl + '/permissions'
                const response = await axios.get(url, { withCredentials: true })

                this.permissions = response.data.permissions.map(Permission)
            },
            async getLotteries() {
                axios.defaults.withCredentials = true

                const url      = this.apiUrl + '/lotteries'
                const response = await axios.get(url, { withCredentials: true })

                this.lotteries = response.data.lotteries.map(Lottery)
            },
            deleteUser() {
                if (!this.modifiedUser.pending_delete) {
                    this.modifiedUser.pending_delete = true
                } else {
                    this.modifiedUser.pending_delete = false
                    this.modifiedUser.account_enabled = 0
                    this.saveUser()
                }
            },
            newUser() {
                this.modifiedUser   = User()
                this.editing        = this.modifiedUser
                this.editing.req_new_pass = 1
            },
            editUser(user) {
                this.modifiedUser   = User(JSON.parse(JSON.stringify(user)))
                this.editing        = user
            },
            async saveUser() {
                this.editLoading = true

                if (!this.modifiedUser.id) {
                    await this.modifiedUser.create()

                } else {
                    await this.modifiedUser.update()
                }

                await this.modifiedUser.setPermissions()
                await this.modifiedUser.setLotteries()
                await this.getUsers()

                this.editing        = null
                this.editLoading    = false

                var message = 'User created!'

                if (!this.modifiedUser.account_enabled) {
                    message = 'User deleted!'
                } else if (this.modifiedUser.id) {
                    message = 'User updated!'
                }

                this.toast(message, {
                    hideProgressBar : true      ,
                    timeout         : 7000      ,
                    icon            : true      ,
                    type            : 'success' ,
                })
            }
        },
        setup() {
            const toast = useToast()
            return { toast }
        },
        mounted() {
            if (!this.haveData) {
                this.getData()
            }

            this.$store.watch(state => {
                return state.isAuthed
            }, isAuthed => {
                if (isAuthed) {
                    this.getData()
                }
            })
        }
    }
</script>

<template>
    <div class=view--users :class="{ 'is--loading': !haveData }">
        <header>
            <h1>Users</h1>
            <button @click="newUser">Add New</button>
        </header>

        <ul v-if="users" class=users>
            <li>
                <ul>
                    <li>User</li>
                    <li>Company</li>
                    <li>Permissions</li>
                    <li>Last Login</li>
                    <li>2FA</li>
                    <li></li>
                </ul>
            </li>
            <li v-for="user in users" :key="user.id">
                <ul>
                    <li data-title="User">
                        <strong>{{ user.first_name }} {{ user.last_name }}</strong>
                        <span>{{ user.email }}</span>
                    </li>
                    <li data-title="Company">
                        {{ user.organisation }}
                    </li>
                    <li data-title="Permissions">
                        <ul>
                            <li v-for="permission in user.permissions" :key="permission.id">{{ permission.description }}</li>
                        </ul>
                    </li>
                    <li data-title="Last Access">
                        <span :title="user.last_auth">{{ user.last_auth_ago }}</span>
                    </li>
                    <li data-title="2FA">
                        {{ user['2fa_enabled'] ? 'Enabled' : 'Not&nbsp;Enabled' }}
                    </li>
                    <li>
                        <button @click="editUser(user)">Edit</button>
                    </li>
                </ul>
            </li>
        </ul>

        <modal class=modal-edit-user v-if="editing" @click="editing = null">
            <form @submit.prevent="saveUser" :class="{ 'is--loading': editLoading, 'is--deleting': editLoading && !modifiedUser.account_enabled, 'delete--pending': modifiedUser.pending_delete }">
                <h3 v-if="!editing.id">New user</h3>

                <h3 v-else>
                    Editing user {{ editing.first_name }} {{ editing.last_name }}

                    <button @click="deleteUser" type=button></button>
                </h3>

                <input-field label="First Name" v-model="modifiedUser.first_name"   :required="true" />
                <input-field label="Last Name"  v-model="modifiedUser.last_name"    :required="true" />
                <input-field label="Email"      v-model="modifiedUser.email"        :required="true" type="email" />
                <input-field label="Company"    v-model="modifiedUser.organisation" :required="true" />

                <label v-if="modifiedUser.id">
                    <input type=checkbox v-model="modifiedUser.reset_password">
                    Set a new password
                </label>

                <input-field v-if="!modifiedUser.id || modifiedUser.reset_password" label="Temporary Password" v-model="modifiedUser.tmp_password" :required="true" description="The user will be prompted to enter a new password when they log in" />

                <label v-if="modifiedUser.id && modifiedUser['2fa_enabled']">
                    <input type=checkbox v-model="modifiedUser.reset_totp_secret">
                    Reset 2FA secret
                    <small>The 2FA QR code will appear when this user next logs in</small>
                </label>

                <h4>Permissions</h4>

                <div class=scroll>
                    <ul class=permissions>
                        <li v-for="permission in permissions" :key=permission.id>
                            <label>
                                <span>{{ permission.description }}</span>
                                <input :disabled="permission.tag == 'administrator' && !authedUser.isAdministrator()" type="checkbox" v-model="modifiedUser.permissions" :value="permission">
                            </label>
                        </li>
                    </ul>
                </div>

                <h4>Site access</h4>

                <p v-if="modifiedUser.canAccessAllLotteries()">This user can access all lotteries</p>

                <div class=scroll v-else>
                    <ul class=lotteries>
                        <li v-for="lottery in lotteries" :key=lottery.id>
                            <label>
                                <span><span>{{ lottery.code }}</span> {{ lottery.label }}</span>
                                <input type="checkbox" v-model="modifiedUser.lotteries" :value="lottery">
                            </label>
                        </li>
                    </ul>
                </div>

                <button>Save</button>
            </form>
        </modal>
    </div>
</template>

<style lang="scss">
    .view--users {
        ul.users {
            list-style: none;
            padding: 0;

            > li {
                padding: 16px 0;
                border-bottom: 1px solid #2f2b2b;

                &:first-child {
                    text-transform: uppercase;
                    letter-spacing: .2em;
                    font-size: .9em;
                }

                > ul {
                    list-style: none;
                    display: grid;
                    grid-template-columns: 20% 15% 30% 13% 9% 13%;
                    gap: 0;
                    padding: 0;

                    > li {
                        padding-right: var(--padding);

                        &:last-child {
                            text-align: right;
                        }
                    }
                }

                [data-title="User"] {
                    span {
                        display: block;
                    }
                }

                [data-title="Permissions"] ul {
                    padding: 0   0 0 1em;
                    font-size: .9em;
                    list-style: '- ' ;
                }

                @media (max-width: 768px) {
                    &:first-child {
                        display: none;
                    }

                    > ul {
                        display: block;

                        > li {
                            &:before {
                                content: attr(data-title);
                                display: block;
                                text-transform: uppercase;
                                letter-spacing: .21em;
                                font-size: 12px;
                                opacity: .8;
                            }

                            &:not(:first-child) {
                                margin-top: 1rem;
                            }

                            &:last-child {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

        .modal-edit-user {
            label {
                display: block;
                margin: 1rem 0;

                small {
                    display: block;
                    font-size: 12px;
                }
            }

            .scroll {
                max-height: 100px;
                overflow: auto;
                background-color: white;
                padding: 1rem;

                label {
                    margin: 0;
                }
            }

            h3 {
                display: flex;
                justify-content: space-between;

                button {
                    width: 20px;
                    height: 20px;
                    background: url('/images/icons/bin.svg') no-repeat right;
                    background-size: contain;
                    border: none;
                    margin: 0;
                    cursor: pointer;

                    &:before {
                        content: 'Delete';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: calc(1rem + 20px);
                        width: 140px;
                        background-color: #D02525;
                        color: white;
                        padding: 10px 20px;
                        font-weight: bold;
                        opacity: 0;
                        transition: .15s;
                    }

                    &:after {
                        left: auto;
                        right: -13px;
                        transform: translate(-50%, -100%);
                    }

                    &:hover {
                        background-color: transparent;

                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }

            .delete--pending h3 button:before {
                content: 'Are you sure?';
            }

            form:not(.is--deleting) h3 button:after {
                opacity: 0;
            }

            .is--deleting h3 button {
                background-image: none;

                &:before {
                    content: none;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    padding-bottom: .5em;

                    label {
                        display: flex;
                        justify-content: space-between;
                    }

                    input {
                        position: relative;
                        left: -5px;
                    }
                }
            }
        }

        form.is--loading button {
            color: rgba(black, 0);
        }
    }
</style>